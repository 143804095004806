import { graphql } from 'gatsby'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Seo from '../../components/Seo'
import { PAGE_VIEWED } from '../../constants/metrics'
import BasicLayout from '../../layouts/BasicLayout'
import MetricsService from '../../services/metricsService'
import PartnerLogo from '../../assets/svgs/partner-logo-showcase-page.svg'
import { useTranslation } from 'react-i18next'
import { Icon } from '@momentum-ui/react'
import Link from '../../components/Link'
import { linkRenderer } from '../../utils/markdown.utils'

export interface IPartnerShowCaseProps {
  data: {
    strapiPartnerShowCases: {
      industry: string
      headquarters: string
      size: string
      content: string
      description: string
      name: string
      link: string
    }
  }
}

export const query = graphql`
  query PartnerShowCaseQuery($name: String!) {
    strapiPartnerShowCases(name: { eq: $name }) {
      name
      industry
      headquarters
      size
      content
      description
      link
    }
  }
`

const PartnerShowcases: React.FC<IPartnerShowCaseProps> = ({
  data,
}): JSX.Element => {
  const {
    name,
    content,
    industry,
    size,
    description,
    link,
    headquarters,
  } = data.strapiPartnerShowCases

  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()
  const title = `${name} Partner Showcase`

  metricsService.track(PAGE_VIEWED, {
    pageName: title,
  })

  return (
    <BasicLayout>
      <Seo title={title}></Seo>
      <article className="partner-showcase">
        <section className="partner-showcase-banner">
          <div className="banner-container">
            <h1>{name}</h1>
            <p>{description}</p>
          </div>
        </section>
        <section className="partner-showcase-body">
          <div className="body-content">
            <ReactMarkdown
              source={content}
              renderers={linkRenderer}
              escapeHtml={false}
            />
          </div>
          <div className="body-right-pane">
            <div className="partner-logo-container">
              {/*TODO: Change this to be dynamic image*/}
              <PartnerLogo
                alt={`${name} ${t('partnerShowcase.companyLogoAltText')}`}
              />
            </div>
            <div className="partner-info">
              <div className="partner-info-row">
                <div className="partner-info-row-cell">
                  <p>{t('partnerShowcase.industry')}</p>
                  <p>{industry}</p>
                </div>
                <div className="partner-info-row-cell">
                  <p>{t('partnerShowcase.size')}</p>
                  <p>{size}</p>
                </div>
              </div>
              <div className="partner-info-row">
                <div className="partner-info-row-cell">
                  <p>{t('partnerShowcase.headquarters')}</p>
                  <p>{headquarters}</p>
                </div>
                <div className="partner-info-row-cell">
                  <p>{t('partnerShowcase.site')}</p>
                  <Link
                    to={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="default-link"
                  >
                    {t('partnerShowcase.goToSite')} <Icon name="pop-out_12" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </BasicLayout>
  )
}

export default PartnerShowcases
